import classNames from 'classnames';

interface Props {
  className?: string;
}

export const DeletePostIcon: React.FC<Props> = (props: Props) => {
  const { className } = props;
  return (
    <svg
      id="Layer_1"
      width="24"
      height="24"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 24 24"
      className={classNames('block', className)}
    >
      <path
        fill="#ee9844"
        d="M19,3.2H5c-1.1,0-2,.9-2,2v14c0,1.1.9,2,2,2h14c1.1,0,2-.9,2-2V5.2c0-1.1-.9-2-2-2Z"
      />
      <path
        fill="#fff"
        d="M13.2,18.2h-2.4c-.7,0-1.1,0-1.4-.2-.3-.2-.6-.4-.8-.7-.2-.4-.2-.7-.3-1.4l-.4-6.6h-.6c-.3,0-.6-.3-.6-.6s.3-.6.6-.6h1.2s0,0,0,0h.8c.2-.6.3-.9.5-1.1.2-.2.4-.4.7-.5.3-.1.6-.1,1.2-.1h.8c.6,0,.9,0,1.2.1.3.1.5.3.7.5.2.3.3.5.5,1.1h0c0,0,.8,0,.8,0,0,0,0,0,0,0h1.2c.3,0,.6.3.6.6s-.3.6-.6.6h-.6l-.4,6.6c0,.7,0,1.1-.3,1.4-.2.3-.4.6-.8.7-.4.2-.8.2-1.4.2ZM9.1,9.3l.4,6.5c0,.5,0,.8.1.9,0,.1.1.2.3.2.1,0,.4,0,.9,0h2.4c.5,0,.8,0,.9,0,.1,0,.2-.1.3-.2,0-.1,0-.4.1-.9l.4-6.5h-.5s0,0,0,0h-4.7s0,0,0,0h-.5ZM10.4,8.1h3.1c0-.2-.1-.3-.2-.4,0,0-.1-.1-.2-.2-.1,0-.3,0-.7,0h-.8c-.4,0-.6,0-.7,0,0,0-.2,0-.2.2,0,0-.1.2-.2.4ZM13.2,15.8c-.3,0-.6-.3-.6-.6v-4.2c0-.3.3-.6.6-.6s.6.3.6.6v4.2c0,.3-.3.6-.6.6ZM10.8,15.8c-.3,0-.6-.3-.6-.6v-4.2c0-.3.3-.6.6-.6s.6.3.6.6v4.2c0,.3-.3.6-.6.6Z"
      />
    </svg>
  );
};
