import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../state';
import {
  selectDiscussions,
  selectDiscussionsLoading,
  selectDiscussionsError
} from '../../../state/selectors/discussionsSelector';
import { getDiscussionsByTopicUrlKey } from '../../../state/actions/discussionsActions';

interface UseGetDiscussionsByTopicUrlKeyData {
  page: number;
  pageSize: number;
  urlKey: string;
  sortMethod?: string;
}

export const useGetDiscussionsByTopicUrlKeyData = ({
  page,
  pageSize,
  urlKey,
  sortMethod
}: UseGetDiscussionsByTopicUrlKeyData) => {
  const dispatch = useAppDispatch();
  const discussions = useAppSelector(selectDiscussions);
  const loading = useAppSelector(selectDiscussionsLoading);
  const error = useAppSelector(selectDiscussionsError);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    const fetchDiscussions = async () => {
      await dispatch(
        getDiscussionsByTopicUrlKey({
          page,
          pageSize,
          urlKey,
          sortMethod,
          signal
        })
      );
    };

    fetchDiscussions();
    return () => {
      controller.abort();
    };
  }, [dispatch, page, pageSize, urlKey, sortMethod]);

  return { discussions, loading, error };
};
// TODO find a way to add the comments count to each discussions without many requests
