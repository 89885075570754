import { FC } from 'react';
import { usePapersDiscussionsTabs } from './hooks/usePapersDiscussionsTabs';
import { usePapersCount } from './hooks/usePaperCount';
import { Tooltip } from '../core/tooltip';
import { useDiscussionsCount } from './hooks/useDiscussionCount';

interface PapersDiscussionsTabsProps {
  hidePapersTab?: boolean;
  contentUrlKey: string;
  type: 'topic' | 'community';
}

export const PapersDiscussionsTabs: FC<PapersDiscussionsTabsProps> = ({
  hidePapersTab,
  contentUrlKey,
  type
}) => {
  const { handlePapersClick, handleDiscussionsClick, radioState } =
    usePapersDiscussionsTabs({
      contentUrlKey,
      hidePapersTab
    });

  const {
    papersAllCount,
    formattedCount: papersFormattedCount,
    loading: papersLoading
  } = usePapersCount(contentUrlKey, type);

  const {
    discussionsAllCount,
    formattedCount: discussionsFormattedCount,
    loading: discussionsLoading
  } = useDiscussionsCount(contentUrlKey, type);

  return (
    <main className="flex flex-col justify-start w-full">
      <div className="tabs-row flex justify-start px-4">
        <div className="tabs max-w-3xl w-full m-auto">
          {!hidePapersTab && (
            <button
              className={`tab-button py-4 ${radioState === 'papers' ? 'active' : ''}`}
              onClick={handlePapersClick}
            >
              <span className="papers-label">
                {!papersLoading ? (
                  <Tooltip
                    className="tabsTooltip"
                    text={`${papersAllCount} papers in the ${type}`}
                  >
                    {'Papers '}
                    {papersAllCount !== 0 && (
                      <>
                        <span className="papers-count">
                          ({papersFormattedCount})
                        </span>
                      </>
                    )}
                  </Tooltip>
                ) : (
                  'Papers'
                )}
              </span>
            </button>
          )}
          <button
            className={`tab-button py-4 ${radioState === 'discussions' ? 'active' : ''}`}
            onClick={handleDiscussionsClick}
          >
            <span className="discussions-label">
              {!discussionsLoading &&
              discussionsAllCount &&
              discussionsAllCount > 0 ? (
                <Tooltip
                  className="tabsTooltip"
                  text={`${discussionsAllCount} ${
                    discussionsAllCount === 1 ? 'discussion' : 'discussions'
                  } in the community`}
                >
                  {'Discussions '}
                  {discussionsAllCount !== 0 && (
                    <>
                      <span className="discussions-count">
                        ({discussionsFormattedCount})
                      </span>
                    </>
                  )}
                </Tooltip>
              ) : (
                'Discussions'
              )}
            </span>
          </button>
        </div>
      </div>
    </main>
  );
};
