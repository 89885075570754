import { AppState } from '../store';

export const selectDiscussions = (state: AppState) =>
  state.discussions.discussions;
export const selectDiscussionsLoading = (state: AppState) =>
  state.discussions.loading;
export const selectDiscussionsError = (state: AppState) =>
  state.discussions.error;
export const selectDiscussionsCount = (state: AppState) =>
  state.discussions.discussionsCount;
