import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../state';
import {
  selectTopics,
  selectTopicsError,
  selectTopicsLoading
} from '../../../state/selectors/topicsSelector';
import { getTopicsData } from '../../../state/actions/topicsActions';

interface UseGetTopicsData {
  page: number;
  pageSize: number;
  sortMethod?: string;
}

export const useGetTopicsData = ({
  page,
  pageSize,
  sortMethod
}: UseGetTopicsData) => {
  const dispatch = useAppDispatch();
  const topics = useAppSelector(selectTopics);
  const loading = useAppSelector(selectTopicsLoading);
  const error = useAppSelector(selectTopicsError);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    const fetchTopics = async () => {
      await dispatch(getTopicsData({ page, pageSize, sortMethod, signal }));
    };

    fetchTopics();
    return () => {
      controller.abort();
    };
  }, [dispatch, page, pageSize, sortMethod]);

  return { topics, loading, error };
};
