import { useEffect, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '../../../state';
import {
  selectDiscussionsCount,
  selectDiscussionsLoading
} from '../../../state/selectors/discussionsSelector';
import { formatCount } from './usePaperCount';
import { resetDiscussionsCount } from '../../../state/slices/discussionsReducer';
import { selectTopic } from '../../../state/selectors/topicSelector';
import { selectCommunityData } from '../../../state/selectors/communitySelector';

export const useDiscussionsCount = (
  contentUrlKey: string,
  type: 'topic' | 'community'
) => {
  const dispatch = useAppDispatch();
  const discussionsCount = useAppSelector(selectDiscussionsCount);
  const discussionsLoading = useAppSelector(selectDiscussionsLoading);

  const topicDiscussions = useAppSelector(selectTopic)?.numDiscussions;
  const communityDiscussions =
    useAppSelector(selectCommunityData)?.numDiscussions;

  const discussionsCountFromCommunity =
    type === 'topic' ? topicDiscussions : communityDiscussions;

  const discussionsAllCount = useMemo(() => {
    if (discussionsCount && discussionsCount > 0) {
      return discussionsCount;
    } else {
      return discussionsCountFromCommunity || 0;
    }
  }, [discussionsCount, discussionsCountFromCommunity]);

  useEffect(() => {
    dispatch(resetDiscussionsCount());
  }, [contentUrlKey, dispatch]);

  // Format the count for display
  const formattedCount = useMemo(
    () => formatCount(discussionsAllCount),
    [discussionsAllCount]
  );

  return { discussionsAllCount, formattedCount, loading: discussionsLoading };
};
