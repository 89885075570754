import React from 'react';
import { Modal } from './modal';
import { Button } from '../form';
import { Loading } from '../core';

interface ConfirmationModalWrapperProps {
  isOpen: boolean;
  title: string;
  message: string;
  onConfirm: () => void;
  onClose: () => void;
  loading?: boolean;
}

export const ConfirmationModalWrapper: React.FC<
  ConfirmationModalWrapperProps
> = ({ isOpen, title, message, onConfirm, onClose, loading }) => {
  const handleConfirm = () => {
    onConfirm();
  };

  return (
    <Modal
      modalName="confirmationModal"
      isOpen={isOpen}
      onClose={onClose}
      title={title}
      className="confirmation-modal main-page right-0"
    >
      <div>{message}</div>
      <div className="modal-footer">
        <div className="mt-6 flex justify-between items-center">
          <Button
            outline={true}
            onClick={onClose}
            className="confirmBtns py-2 tablet:px-12 px-6"
          >
            Cancel
          </Button>
          <Button
            className={`confirmBtns py-2 ml-2 ${loading ? 'tablet:px-8 px-4' : 'tablet:px-12 px-6'}`}
            type="submit"
            onClick={handleConfirm}
            disabled={loading}
          >
            {loading ? (
              <>
                Confirm <Loading className="loadingConfirm" />
              </>
            ) : (
              'Confirm'
            )}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
