import { createAsyncThunk } from '@reduxjs/toolkit';
import { env } from '../../env';
import { ICommunitySummaryPaged } from '../../types/communities.type';

export const getCommunitiesData = createAsyncThunk<
  ICommunitySummaryPaged,
  {
    page: number;
    pageSize: number;
    communityType: number;
    sortMethod?: string;
    target?: 'default' | 'creation';
    signal: AbortSignal;
  }
>(
  'get/getCommunitiesData',
  async (
    { page, pageSize, communityType, sortMethod, signal },
    { rejectWithValue }
  ) => {
    const query =
      `communityType=${communityType}&page=${page}&pageSize=${pageSize}` +
      (sortMethod ? `&order=${sortMethod}` : '');
    const url = `${env.apiUrl}/api/v1/communities?${query}`;

    try {
      const response = await fetch(url, {
        method: 'GET',
        signal,
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        throw new Error('Failed to fetch communities');
      }

      const data: ICommunitySummaryPaged = await response.json();
      return data;
    } catch (error) {
      return rejectWithValue('Failed to fetch communities');
    }
  }
);

export const getLandingCommunitiesData = createAsyncThunk<
  ICommunitySummaryPaged,
  { page: number; pageSize: number; communityType: number; sortMethod?: string }
>(
  'get/getLandingCommunitiesData',
  async (
    { page, pageSize, communityType, sortMethod },
    { rejectWithValue }
  ) => {
    const query =
      `communityType=${communityType}&page=${page}&pageSize=${pageSize}` +
      (sortMethod ? `&order=${sortMethod}` : '');
    const url = `${env.apiUrl}/api/v1/communities?${query}`;

    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        throw new Error('Failed to fetch communities');
      }

      const data: ICommunitySummaryPaged = await response.json();
      return data;
    } catch (error) {
      return rejectWithValue('Failed to fetch communities');
    }
  }
);

export const searchCommunities = createAsyncThunk<
  ICommunitySummaryPaged,
  {
    term: string;
    page: number;
    pageSize: number;
    target?: 'default' | 'creation';
    abortController?: AbortController;
  },
  { rejectValue: string }
>(
  'search/searchCommunities',
  async ({ term, page, pageSize, abortController }, { rejectWithValue }) => {
    const url = `${env.apiUrl}/api/v1/communities/search?q=${encodeURIComponent(term)}&pageNum=${page}&pageSize=${pageSize}`;
    const signal = abortController?.signal;

    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        },
        signal
      });

      if (response.status === 404) {
        return rejectWithValue('No results found for your search query');
      }

      if (!response.ok) {
        const errorData = await response.json();
        return rejectWithValue(
          errorData.message ||
            'We couldn&apos;t process your search at this minute. We&apos;reactively working on improving this site and will iron out any issues. In the meantime, please search for something different.'
        );
      }

      const data: ICommunitySummaryPaged = await response.json();
      return data;
    } catch (error: any) {
      if (error.name === 'AbortError') {
        return rejectWithValue('Request was aborted');
      }
      return rejectWithValue(error.message || 'Failed to fetch communities');
    }
  }
);
