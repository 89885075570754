import { FC, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { ShareButton } from '../../components/share';
import { PapersCount } from '../../svgs/PapersCount';
import { DiscussionIcon } from '../../svgs/DiscussionIcon';
import { createLink } from '../../utils/helperFunctions';
import { ITopicSummary } from '../../types/topic.type';
import { Tooltip } from '../../components/core/tooltip';

interface Props {
  topic: ITopicSummary;
  handleClick?: () => void;
}

export const TopicSummaryV3: FC<Props> = ({ topic }) => {
  const topicLink = useMemo(() => {
    return createLink(`/topic/${topic.urlKey}/papers`);
  }, [topic]);

  const shareUrl = useMemo(() => {
    const currentUrl = window.location.protocol + '//' + window.location.host;
    return currentUrl + topicLink;
  }, [topicLink]);

  const formatNumber = (number: number) =>
    new Intl.NumberFormat().format(number);

  // const handleTopicSinglePageDiscussionTab = useCallback(
  //   (e: React.MouseEvent<HTMLButtonElement>) => {
  //     e.preventDefault();
  //     navigate(topicLink + '/discussions');
  //   },
  //   [navigate, topicLink]
  // );

  return (
    <Link to={topicLink} className="item cursor-pointer w-full">
      <div className="itemTitle font-bold flex mb-3 cursor-pointer">
        <div className="text-sm text-gray-900 hover:text-primary-500 transition-smooth-color leading-tight">
          {topic.name}
        </div>
      </div>
      <hr></hr>
      <div className="itemActions flex justify-between align-center text-black mt-4">
        <div className="itemActionPaperCount">
          <div className="flex items-center">
            <PapersCount />
            <span className="text-half ml-1">
              {formatNumber(topic.numPapers || 0)}
            </span>
          </div>
        </div>
        <div className="itemActionDiscussions">
          <div
            // type="button"
            // onClick={handleTopicSinglePageDiscussionTab}
            className="flex items-center"
          >
            <Tooltip
              className="tooltipDisc"
              text={'The total number of active discussions'}
            >
              <DiscussionIcon />
              <span className="text-half ml-1">
                {formatNumber(topic.numDiscussions || 0)}
              </span>
            </Tooltip>
          </div>
        </div>
        <div className="itemActionShare">
          <div className="flex items-center">
            <ShareButton url={shareUrl} />
          </div>
        </div>
      </div>
    </Link>
  );
};
