import { FC, useState } from 'react';
import { PapersList } from './papers-list';
import { DiscussionsList } from './discussions-list';
import { useAppSelector } from '../../state';
import TopicTitlePlace from '../../routes/topic-v2/topicTitlePlace';
import { PapersDiscussionsTabs } from './papersDiscussionsTabs';
import { selectIsUserAdmin } from '../../state/selectors/userSelector';
import { DeletedUserPostsListTopic } from '../userPosts';
import { AdminSidebar } from '../adminSidebar';
import { useParams } from 'react-router-dom';

interface PapersDiscussionsTabsTopicProps {
  topicName: string | null | undefined;
  urlKey: string | null | undefined;
}

export const PapersDiscussionsTabsTopic: FC<
  PapersDiscussionsTabsTopicProps
> = ({ topicName, urlKey }) => {
  const isUserAdmin = useAppSelector(selectIsUserAdmin);
  const radioState = useAppSelector(
    (state) => state.tabs.papersDiscussionsRadioState
  );
  const [showDeletedPosts, setShowDeletedPosts] = useState(false);
  const { urlKeyFromUrl } = useParams();

  return (
    <div className="h-full w-full">
      <div className="flex flex-col h-full w-full">
        <PapersDiscussionsTabs
          contentUrlKey={`topic/${urlKey || urlKeyFromUrl}`}
          type="topic"
        />
        <div className="maincont flex flex-row w-full">
          <div className="pagepart w-full h-full">
            <TopicTitlePlace topicName={topicName} />
            <div className=" flex w-full flex-row h-full">
              <div className="flex w-full flex-col">
                {isUserAdmin && showDeletedPosts ? (
                  <DeletedUserPostsListTopic />
                ) : radioState === 'papers' ? (
                  <PapersList isCommunity={false} />
                ) : (
                  <DiscussionsList isCommunity={false} />
                )}
              </div>
            </div>
          </div>
          {isUserAdmin && (
            <AdminSidebar
              showDeletedPosts={showDeletedPosts}
              setShowDeletedPosts={setShowDeletedPosts}
            />
          )}
        </div>
      </div>
    </div>
  );
};
