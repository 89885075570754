import React from 'react';
import { fetchCommunityPapersData, useAppDispatch } from '../../state';
import {
  resetCommunityPaperFilterData,
  setCommunityPaperFilterData
} from '../../state/slices/filterPapersReducer';
import { PapersMainFilter } from '.';
import { useParams } from 'react-router-dom';

export const PapersCommunityFilter: React.FC = ({}) => {
  const dispatch = useAppDispatch();
  const { urlKey } = useParams();
  return (
    <PapersMainFilter
      urlKey={urlKey!}
      setFilterData={(filterData) =>
        dispatch(setCommunityPaperFilterData(filterData))
      }
      fetchData={(filterData) =>
        dispatch(
          fetchCommunityPapersData({
            ...filterData,
            communityUrlKey: urlKey
          })
        )
      }
      resetFilterData={() => dispatch(resetCommunityPaperFilterData())}
      type="community"
    />
  );
};
