import classNames from 'classnames';

interface Props {
  className?: string;
}

export const Unsubscribe: React.FC<Props> = (props: Props) => {
  const { className } = props;
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames('block  h-full', className)}
    >
      <path
        fill="#ed8936"
        d="M12 0a2 2 0 0 0-1.81 1.15L7.556 6.758 1.7 7.642a2 2 0 0 0-1.13 3.374l4.3 4.408-1.02 6.235a2 2 0 0 0 2.94 2.073L12 20.86l5.216 2.89a2 2 0 0 0 2.942-2.073l-1.02-6.237 4.293-4.39a2 2 0 0 0-1.124-3.376l-5.857-.9-2.64-5.624A2 2 0 0 0 12 0z"
      />
    </svg>
  );
};
