import { useNavigate, useLocation } from 'react-router-dom';
import { useQueryParams } from '../../../hooks/useQueryParams';

export const useFilterPagination = () => {
  const queryParams = useQueryParams();
  const navigate = useNavigate();
  const location = useLocation();

  const updatePageForFilter = (newPage: number) => {
    const updatedParams = {
      ...queryParams,
      pageNum: newPage.toString()
    };
    navigate({
      pathname: location.pathname,
      search: `?${new URLSearchParams(updatedParams).toString()}`
    });
  };

  return { updatePageForFilter };
};
