import React from 'react';
import { useNavigate } from 'react-router-dom';
import { CreateCommunity } from './createCommunity';
import { useCommunityForm } from '../hooks/useCommunityForm';
import { createLink } from '../../../utils/helperFunctions';
import { createCommunity, useAppDispatch } from '../../../state';
import { unwrapResult } from '@reduxjs/toolkit';

interface Props {
  closeModal: () => void;
}

export const CreateCommunityContainer: React.FC<Props> = ({ closeModal }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    communityName,
    description,
    selectedTopicTags,
    topicTags,
    onRemoveTopic,
    onFilterTopics,
    onAddTopic,
    setCommunityName,
    setDescription,
    errors,
    inputTopicsValue,
    setErrors,
    fetchMoreTopics,
    hasMoreTopicPage,
    isSubscribedToNews,
    setIsSubscribedToNews
  } = useCommunityForm(false);

  const handleCreate = async () => {
    try {
      const topicUrlKeys = selectedTopicTags.map((tag) => tag.urlKey);
      const response = await dispatch(
        createCommunity({
          name: communityName,
          description,
          topics: topicUrlKeys,
          subscribeToNews: isSubscribedToNews
        })
      );
      const community = unwrapResult(response);
      const urlKey = community.urlKey;
      closeModal();
      if (typeof urlKey === 'string') {
        navigate(createLink(`/community/${urlKey}/papers`));
      }
    } catch (error: any) {
      setErrors((errors) => ({
        ...errors,
        createError: error
      }));
    }
  };

  return (
    <CreateCommunity
      topicTags={topicTags}
      selectedTopicTags={selectedTopicTags}
      removeTopic={onRemoveTopic}
      filterTopics={onFilterTopics}
      addTopic={onAddTopic}
      setCommunityName={setCommunityName}
      communityName={communityName}
      setDescription={setDescription}
      description={description}
      handleCreate={handleCreate}
      errors={errors}
      inputTopicsValue={inputTopicsValue}
      fetchMoreTopics={fetchMoreTopics}
      hasMoreTopicPage={hasMoreTopicPage}
      isSubscribedToNews={isSubscribedToNews}
      setIsSubscribedToNews={setIsSubscribedToNews}
    />
  );
};
