import { useCallback } from 'react';
import { useAppDispatch } from '../../../state';
import {
  getCommunity,
  updateCommunity
} from '../../../state/actions/communityActions';

interface IUseUpdateCommunityParams {
  urlKey: string;
  communityName?: string;
  description: string;
  selectedTopicTags: string[];
  isSubscribedToNews: boolean;
  isUserAdmin: boolean;
  isUserCreator: boolean;
}

export function useUpdateCommunity(
  onSuccess?: (newUrlKey?: string) => void,
  onError?: (error: any) => void
) {
  const dispatch = useAppDispatch();

  return useCallback(
    ({
      urlKey,
      communityName,
      description,
      selectedTopicTags,
      isSubscribedToNews
    }: IUseUpdateCommunityParams) => {
      const adminData = {
        urlKey,
        data: {
          name: communityName ?? '',
          description,
          topics: selectedTopicTags,
          subscribeToNews: isSubscribedToNews
        }
      };

      dispatch(updateCommunity(adminData))
        .unwrap()
        .then((response) => {
          const updatedUrlKey = response.urlKey;
          if (updatedUrlKey && updatedUrlKey !== urlKey) {
            onSuccess?.(updatedUrlKey);
          } else {
            dispatch(getCommunity({ urlKey }));
            onSuccess?.();
          }
        })
        .catch(onError);
    },
    [dispatch, onSuccess, onError]
  );
}
