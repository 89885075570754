import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ITopicSummary } from '../../types/topic.type';
import { getTopic } from '../actions/topicActions';

interface TopicState {
  topic: ITopicSummary | null;
  loading: boolean;
  error: string | null;
}

const initialState: TopicState = {
  topic: null,
  loading: false,
  error: null
};

const topicSlice = createSlice({
  name: 'topics',
  initialState,
  reducers: {
    clearTopic(state) {
      state.topic = null;
      state.loading = false;
      state.error = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTopic.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getTopic.fulfilled,
        (state, action: PayloadAction<ITopicSummary>) => {
          state.topic = action.payload;
          state.loading = false;
        }
      )
      .addCase(getTopic.rejected, (state, action) => {
        state.error = action.error.message || 'Failed to fetch topics';
        state.loading = false;
      });
  }
});

export const { clearTopic } = topicSlice.actions;
export const topicReducer = topicSlice.reducer;
