import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  getDiscussionsByTopicUrlKey,
  getDiscussionsByCommunityUrlKey
} from '../actions/discussionsActions';
import { IDiscussionsPaged } from '../../types/discussions.type';

interface DiscussionsState {
  discussions: IDiscussionsPaged | null;
  loading: boolean;
  error: string | null;
  discussionsCount?: number;
}

const initialState: DiscussionsState = {
  discussions: null,
  loading: false,
  error: null,
  discussionsCount: 0
};

const discussionsSlice = createSlice({
  name: 'discussions',
  initialState,
  reducers: {
    resetDiscussionsCount: (state) => {
      state.discussionsCount = 0;
    }
  },
  extraReducers: (builder) => {
    // Get Discussions By Topic URL Key
    builder
      .addCase(getDiscussionsByTopicUrlKey.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getDiscussionsByTopicUrlKey.fulfilled,
        (state, action: PayloadAction<IDiscussionsPaged>) => {
          state.discussions = action.payload;
          state.discussionsCount = action.payload.count;
          state.loading = false;
        }
      )
      .addCase(getDiscussionsByTopicUrlKey.rejected, (state, action) => {
        if (
          action.error.name === 'AbortError' ||
          action.error.message === 'Rejected'
        ) {
          return; // Do nothing if it's an AbortError
        }
        state.error =
          action.error.message ||
          'Failed to fetch discussions for the topic URL key.';
        state.loading = false;
      });

    // Get Discussions By Community URL Key
    builder
      .addCase(getDiscussionsByCommunityUrlKey.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getDiscussionsByCommunityUrlKey.fulfilled,
        (state, action: PayloadAction<IDiscussionsPaged>) => {
          state.discussions = action.payload;
          state.discussionsCount = action.payload.count;
          state.loading = false;
        }
      )
      .addCase(getDiscussionsByCommunityUrlKey.rejected, (state, action) => {
        if (
          action.error.name === 'AbortError' ||
          action.error.message === 'Rejected'
        ) {
          return; // Do nothing if it's an AbortError
        }
        state.error =
          action.error.message ||
          'Failed to fetch discussions for the community URL key.';
        state.loading = false;
      });
  }
});

export const discussionsReducer = discussionsSlice.reducer;
export const { resetDiscussionsCount } = discussionsSlice.actions;
