export const serializeFilters = (filters: Record<string, any>): string =>
  JSON.stringify(
    Object.fromEntries(
      Object.entries(filters)
        .filter(
          ([key, value]) =>
            [
              'title',
              'publishedDateFrom',
              'publishedDateTo',
              'author',
              'journal',
              'pageNum',
              'pageSize'
            ].includes(key) && value
        )
        .sort(([a], [b]) => a.localeCompare(b))
    )
  );
